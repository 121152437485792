const NUM_WALLET = 0;
const NUM_CARD = 1;

const WALLET = "Wallet";
const CARD = "Tarjeta";

const options = [
  { value: NUM_WALLET, text: WALLET },
  { value: NUM_CARD, text: CARD }
];

const values = [NUM_WALLET, NUM_CARD];

export default {
  options,
  values,
  NUM_WALLET,
  NUM_CARD
};
