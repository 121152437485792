<template>
  <z-button
    v-if="canDownloadCSV"
    v-tooltip.top="'Descargar CSV'"
    data-test-id="download-btn"
    class="order-1 order-md-0 mr-md-3 px-4"
    variant="secondary"
    fit="auto"
    :loading="loading"
    :disabled="loading"
    @click="downloadCSV"
  >
    <font-awesome-icon icon="download" size="lg" />
  </z-button>
</template>

<script>
import FileDownload from "@/fileDownload";
import ServiceStatus from "@zubut/common/src/constants/services/status";
import PaymentType from "@/constants/filters/payments";
import RoleType from "@/constants/roles/type";
import DateFilters from "@zubut/common/src/constants/filters/date";
import ServiceType from "@zubut/common/src/constants/services/type";
import { dateRange, formatToISO } from "@zubut/common/src/utils/time";
import CSVContainers from "@/services/csvContainers";
import parseISO from "date-fns/parseISO";
import { endOfDay } from "date-fns";
import ExtraFilters from "./extra-filters";

export default {
  name: "ServicesDownload",

  props: {
    dateFilterType: {
      type: Number,
      default: null
    },
    status: {
      type: Number,
      default: null
    },
    until: {
      type: [String, Date],
      default: null
    },
    from: {
      type: [String, Date],
      default: null
    },
    serviceType: {
      type: Number,
      default: null
    },
    vehicleType: {
      type: Number,
      default: null
    },
    searchText: {
      type: String,
      default: null
    },
    paymentMethod: {
      validator: val => PaymentType.values.concat(null).indexOf(val) > -1,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    userRoleType: {
      type: Number,
      default: null
    }
  },

  computed: {
    canDownloadCSV() {
      return (
        this.userRoleType === RoleType.NUM_SUPER_ADMIN ||
        this.userRoleType === RoleType.NUM_OPERATIONS
      );
    }
  },

  methods: {
    downloadCSV() {
      this.$emit("update:loading", true);
      const where = this.buildWhereFilter();
      CSVContainers.generateServices(where)
        .then(
          res => {
            FileDownload(res, "services.csv", "application/octet-stream");
          },
          err => {
            if (err.statusCode === 404) {
              this.$emit(
                "error",
                "Error al exportar datos, no hay datos disponibles."
              );
            } else {
              const message =
                err?.message ||
                "Ocurrió un error al intentar descargar el archivo";
              this.$emit("error", message);
              this.$captureError(err);
            }
          }
        )
        .catch(err => {
          const message =
            err?.message || "Ocurrió un error al intentar descargar el archivo";
          this.$emit("error", message);
          this.$captureError(err);
        })
        .finally(() => this.$emit("update:loading", false));
    },

    buildWhereFilter() {
      const where = { like: this.searchText };

      /* Set service type filter */
      if (this.serviceType !== null) {
        if (ServiceType.isMultiPoint(this.serviceType)) {
          const { NUM_SIMPLE, NUM_MULTI_POINT, NUM_DYNAMIC } = ServiceType;
          where.type = [NUM_SIMPLE, NUM_MULTI_POINT, NUM_DYNAMIC];
        } else if (ServiceType.isParcel(this.serviceType)) {
          where.type = ServiceType.parcelTypes;
        } else {
          where.type = [this.serviceType];
        }
      }

      /* Set vehicle type filter */
      if (this.vehicleType !== null) {
        where.vehicleType = this.vehicleType;
      }

      if (this.status === null) {
        where.status = ServiceStatus.COMPLETED_STATUS;
      } else if (ServiceStatus.isValidCompletedStatus(this.status)) {
        where.status = this.status;
      } else if (this.status === ExtraFilters.NUM_INCIDENCES) {
        // Service with incidences
        where.hasIncidence = true;
      } else if (this.status === ExtraFilters.NUM_RECALCULATED) {
        // Recalculated service
        where.recalculated = true;
      }

      // Archived service
      if (this.status === ExtraFilters.NUM_ARCHIVED) {
        where.archived = true;
      } else {
        where.archived = false;
      }

      if (
        this.dateFilterType !== null &&
        this.dateFilterType !== DateFilters.NUM_CUSTOM
      ) {
        const newDate = dateRange(this.dateFilterType);
        newDate.from = formatToISO(newDate.from);
        newDate.until = formatToISO(newDate.until);
        where.range = {
          from: newDate.from,
          until: newDate.until
        };
      }

      if (this.from && this.until) {
        const from =
          this.from instanceof Date ? formatToISO(this.from) : this.from;
        const until =
          this.until instanceof Date ? this.until : parseISO(this.until);
        where.range = {
          from,
          until:
            this.dateFilterType === DateFilters.NUM_CUSTOM
              ? formatToISO(endOfDay(until))
              : formatToISO(until)
        };
      }

      if (this.paymentMethod != null) {
        if (this.paymentMethod === PaymentType.NUM_WALLET) {
          where.walletId = { neq: null };
        }
        if (this.paymentMethod === PaymentType.NUM_CARD) {
          where.cardId = { neq: null };
        }
      }

      return where;
    }
  }
};
</script>
