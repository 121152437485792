<template>
  <b-row>
    <b-col class="d-flex flex-column flex-md-row justify-content-md-between">
      <span class="card-title">
        Historial de servicios
      </span>
      <b-row>
        <b-col cols="12" md="auto" class="mb-3">
          <z-dropdown-cities id="cities-dropdown" />
        </b-col>
        <b-col md="auto">
          <z-button
            v-tooltip.top="'Refrescar'"
            fit="auto"
            variant="secondary"
            class="mr-3 mb-3 md-md-0 px-4 w-100"
            @click="handleRefresh"
          >
            <font-awesome-icon
              :class="{ 'animation-spin-reverse': spinRefreshButton }"
              icon="reload"
              size="lg"
            />
          </z-button>
        </b-col>
        <b-col md="auto">
          <services-download
            class="order-1 order-md-0 mr-md-3 w-100"
            :date-filter-type="selectedFilter.date"
            :from="date.from"
            :until="date.until"
            :service-type="filterServiceType"
            :status="filterStatus"
            :vehicle-type="filterVehicleType"
            :search-text="searchText"
            :payment-method="paymentMethod"
            :user-role-type="userRoleType"
            :loading.sync="loadingCSV"
            @error="handleDownloadError"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import ZDropdownCities from "@/app/components/ZDropdownCities";
import ServicesDownload from "./ServicesDownload";

export default {
  name: "ServicesHeader",

  components: {
    ServicesDownload,
    ZDropdownCities
  },

  props: {
    loadingServices: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      hasError: false,
      message: "",
      emittedRefresh: false,
      loadingCSV: false
    };
  },

  computed: {
    selectedFilter() {
      return this.$store.getters["services/filter"];
    },

    date() {
      return this.$store.getters["services/date"];
    },

    filterServiceType() {
      return this.$store.state.services.filter.serviceType;
    },

    filterStatus() {
      return this.$store.state.services.filter.status;
    },

    filterVehicleType() {
      return this.$store.state.services.filter.vehicleType;
    },

    paymentMethod() {
      return this.$store.getters["services/paymentMehodFilter"];
    },

    searchText() {
      return this.$store.state.services.searchText;
    },

    spinRefreshButton() {
      return this.emittedRefresh && this.loadingServices;
    },

    userRoleType() {
      return this.$store.state.auth.user.role.type;
    }
  },

  watch: {
    loadingServices(val) {
      if (val === false) {
        this.emittedRefresh = false;
      }
    }
  },

  methods: {
    handleDownloadError(message) {
      this.$emit("error", message);
    },
    handleRefresh() {
      this.emittedRefresh = true;
      this.$emit("refresh");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@zubut/common/src/styles/animation/spin.scss";

.spinner {
  max-height: 30px;
  padding-top: 10px;
}

::v-deep #cities-dropdown {
  height: 100%;

  button {
    height: 100%;
  }
}
</style>
