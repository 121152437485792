<template>
  <div>
    <b-card>
      <div class="p-3">
        <services-header
          :loading-services="isLoading"
          @error="handleDownloadError"
          @refresh="fetchServices"
        />
        <b-row class="mt-4">
          <b-col lg="3" xl="2">
            <z-search-input
              v-model="searchText"
              placeholder="Busca por ID, cliente, mensajero"
            />
          </b-col>
          <b-col lg="2" class="mt-3 mt-lg-0">
            <z-dropdown
              id="dropdown-date"
              v-model="selectedFilter.date"
              :options="dateOptions"
              name="Fecha"
            />
          </b-col>
          <b-col
            v-show="showDatePicker"
            sm="6"
            lg="3"
            xl="2"
            class="mt-3 mt-lg-0"
          >
            <z-date-picker
              v-model="date.from"
              calendar-class="date-picker"
              :disabled-dates="disableDatesFrom"
              input-class="form-control bg-white w-100"
              placeholder="Desde"
            />
          </b-col>
          <b-col
            v-show="showDatePicker"
            sm="6"
            lg="3"
            xl="2"
            class="mt-3 mt-lg-0"
          >
            <z-date-picker
              v-model="date.until"
              calendar-class="date-picker"
              :disabled-dates="disableDatesUntil"
              input-class="form-control bg-white w-100"
              placeholder="Desde"
            />
          </b-col>
          <b-col lg="2" class="mt-3 mt-lg-0 d-none d-lg-block">
            <z-dropdown
              id="dropdown-date"
              v-model="filterStatus"
              :options="statusOptions"
              name="Estado"
            />
          </b-col>
          <b-col lg="2" class="mt-3 mt-lg-0 d-none d-lg-block">
            <z-dropdown
              id="dropdown-date"
              v-model="filterServiceType"
              :options="serviceTypeOptions"
              name="Tipo de servicio"
            />
          </b-col>
          <b-col lg="2">
            <div
              v-show="showCleanfilter"
              class="mt-4 mt-sm-0 text-center text-sm-left action"
              @click="resetFilter"
            >
              Borrar filtros
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <quick-message
              class="mt-3"
              with-icon
              :show.sync="hasError"
              :message="message"
              type="error"
            />
            <z-table
              class="mt-4"
              hover
              responsive="sm"
              :busy="isLoading"
              :items="tableItems"
              :fields="fields"
              :per-page="perPage"
              :current-page="currentPage"
              @row-clicked="ViewServiceDetail"
            >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <loading-spinner />
                </div>
              </template>
              <template v-slot:empty>
                <p class="text-center py-5 my-5 font-weight-semi-bold">
                  No hay servicios registrados en este filtro
                </p>
              </template>
              <!-- Change styles in headers -->
              <template v-slot:head(createdAt)>
                <div
                  class="cursor-pointer"
                  style="width: 150px;"
                  @click="chageSort"
                >
                  Fecha
                  <font-awesome-icon
                    v-show="sortOrder === 'asc'"
                    icon="sort"
                    size="lg"
                  />
                  <font-awesome-icon
                    v-show="sortOrder === 'desc'"
                    icon="sort"
                    size="lg"
                    flip="vertical"
                  />
                </div>
              </template>
              <template v-slot:head(records)>
                <z-tooltip
                  class="delivery-method-tooltip"
                  message="Entregados / Retornados"
                >
                  <div>Registros</div>
                </z-tooltip>
              </template>
              <template v-slot:head(paymentType)>
                <div>
                  <z-table-header-dropdown
                    id="dropdown-status"
                    v-model="paymentMethodFilterValue"
                    class="d-none d-lg-block"
                    :options="paymentMethodOptions"
                    name="Pago con"
                  />
                </div>
              </template>
              <!-- Template for service type -->
              <template v-slot:head(type)>
                <div>
                  <div class="d-lg-none">Servicio</div>
                  <z-table-header-dropdown
                    id="dropdown-service-type"
                    v-model="filterServiceType"
                    class="d-none d-lg-block"
                    button-class="text-center"
                    :options="serviceTypeOptions"
                  />
                </div>
              </template>
              <!-- Template for vehicle type -->
              <template v-slot:head(vehicleType)>
                <div>
                  <div class="d-lg-none">Vehículo</div>
                  <z-table-header-dropdown
                    id="dropdown-vehicle-type"
                    v-model="filterVehicleType"
                    class="d-none d-lg-block"
                    button-class="text-center"
                    :options="vehicleTypeOptions"
                  />
                </div>
              </template>
              <!-- Template for status -->
              <template v-slot:head(status)>
                <div>
                  <div class="d-lg-none">Estado</div>
                  <z-table-header-dropdown
                    id="dropdown-status"
                    v-model="filterStatus"
                    class="d-none d-lg-block"
                    :options="statusOptions"
                    name="Estado"
                  />
                </div>
              </template>
              <!-- Template for vehicle type -->
              <template v-slot:cell(type)="row">
                <span>
                  <z-tooltip
                    class="additional-hours-tooltip"
                    :message="
                      row.item.additionalHours
                        ? 'Reservación con horas extras'
                        : ''
                    "
                  >
                    <service-type-avatar
                      :type="row.item.type"
                      class="additional-hours-avatar"
                      size="md"
                      :active="row.item.additionalHours ? true : false"
                    />
                  </z-tooltip>
                </span>
              </template>
              <!-- Template for vehicle type -->
              <template v-slot:cell(vehicleType)="data">
                <span>
                  <font-awesome-icon
                    class="vehicle"
                    :icon="vehicle(data.value)"
                    size="2x"
                  />
                </span>
              </template>
              <template v-slot:cell(status)="row">
                <status-service
                  combined
                  :status="row.item.status"
                  :payment-status="row.item.paymentStatus"
                  :billing-status="row.item.billingStatus"
                  :cancel-reason="row.item.cancelReason"
                />
              </template>
              <template v-slot:cell(records)="row">
                <div>
                  {{ parseRegistry(row.item) }}
                </div>
              </template>
              <template v-slot:cell(paymentType)="row">
                <div>{{ paymentType(row.item) }}</div>
              </template>
              <template v-slot:cell(options)="{ item: service }">
                <service-actions-menu
                  :service-id="service.id"
                  :status="service.status"
                  :archived="service.archived"
                  @driver-detail="ViewDriverDetail(service.driverId)"
                  @client-detail="ViewUserDetail(service.clientId)"
                  @service-detail="ViewServiceDetail(service)"
                  @archive="archive(service.id)"
                  @services-log="ViewServiceLogs(service.id)"
                />
              </template>
            </z-table>
            <z-table-pagination
              :total-rows="totalServices"
              :per-page.sync="perPage"
              :current-page.sync="currentPage"
            />
          </b-col>
        </b-row>
      </div>
    </b-card>
    <services-floating-button-filter class="d-lg-none" />
  </div>
</template>

<script>
import DateFilters from "@/constants/filters/date";
import Access from "@/constants/access";
import ServiceStatusConstants from "@/constants/services/status";
import ServiceType from "@zubut/common/src/constants/services/type";
import VehicleType from "@/constants/vehicles/type";
import PaymentType from "@/constants/filters/payments";
import ZDropdown from "@zubut/common/src/components/ZDropdown";
import ZSearchInput from "@zubut/common/src/components/ZSearchInput";
import ZTableHeaderDropdown from "@zubut/common/src/components/ZTableHeaderDropdown";
import StatusService from "@zubut/common/src/components/StatusService";
import ZTablePagination from "@zubut/common/src/components/ZTablePagination";
import ServiceActionsMenu from "@/app/components/ServiceActionsMenu";
import ZTooltip from "@zubut/common/src/components/ZTooltip";
import ZTable from "@zubut/common/src/components/ZTable.vue";
import ServicesHeader from "./ServicesHeader";
import ServicesFloatingButtonFilter from "./ServicesFloatingButtonFilter";
import ServiceTypeAvatar from "@zubut/common/src/components/ServiceTypeAvatar";
import { initialize } from "@/utils/array";
import ZDatePicker from "@zubut/common/src/components/ZDatePicker";
import {
  dateRange,
  formatISO,
  formatToISO
} from "@zubut/common/src/utils/time";
import endOfDay from "date-fns/endOfDay";
import parseISO from "date-fns/parseISO";
import notifyMixin from "@/mixins/notify";
import filtering from "@/mixins/filtering";
import _debounce from "lodash/debounce";
import * as mutation from "@/store/modules/services/mutations-types";
import ExtraFilters from "./extra-filters";
import UserServiceTypeOptions from "@/utils/user-service-type-options";

export default {
  name: "Services",

  components: {
    ServiceActionsMenu,
    ServiceTypeAvatar,
    ServicesFloatingButtonFilter,
    ServicesHeader,
    StatusService,
    ZDatePicker,
    ZDropdown,
    ZSearchInput,
    ZTable,
    ZTableHeaderDropdown,
    ZTablePagination,
    ZTooltip
  },

  mixins: [notifyMixin("notifications"), filtering("services")],

  data() {
    return {
      serviceAccess: Access.services,
      isLoading: true,
      sortBy: "createdAt",
      sortOrder: "desc",
      paymentMethodOptions: [{ text: "Todos", value: null }].concat(
        PaymentType.options
      ),
      vehicleTypeOptions: [{ text: "Todos", value: null }].concat(
        VehicleType.options
      ),
      fields: [
        {
          key: "type",
          label: "Servicio",
          class: "text-center",
          formatter: val => ServiceType.get[val]
        },
        { key: "shortId", label: "ID" },
        {
          key: "createdAt",
          label: "Fecha",
          formatter: val => formatISO(val, "d 'de' LLLL 'de' Y H:mm")
        },
        { key: "client", label: "Cliente" },
        { key: "driver", label: "Mensajero" },
        { key: "status", label: "Estado" },
        { key: "records", label: "Registros", class: "text-center" },
        { key: "vehicleType", class: "text-center" },
        { key: "estimatedRate", label: "Costo" },
        { key: "paymentType", label: "Pago con" },
        { key: "options", label: "Opciones", class: "text-right" }
      ],
      dateOptions: DateFilters.options,
      placeholderItem: {
        shortId: "",
        userName: "",
        driver: "",
        estimatedRate: "",
        createdAt: null,
        status: 0
      },
      statusNo: ServiceStatusConstants,
      unsuscribe: null,
      hasError: false,
      message: ""
    };
  },

  computed: {
    selectedFilter: {
      get() {
        return this.$store.getters["services/filter"];
      },
      set() {}
    },

    date: {
      get() {
        return this.$store.getters["services/date"];
      },
      set(value) {
        return this.$store.commit("services/setDate", value);
      }
    },

    hasServices() {
      if (this.services && this.services.length > 0) {
        return true;
      }
      return false;
    },

    hasStatisticsFilter() {
      return this.$store.state.services.filter.hasStatisticsFilter;
    },

    services() {
      return this.$store.getters["services/getServices"];
    },

    totalServices() {
      return this.$store.state.services.totalServices;
    },

    tableItems() {
      let services = initialize({
        value: this.placeholderItem,
        size: this.totalServices
      });
      services.splice(
        this.pagination.skip,
        this.services.length,
        ...this.services
      );
      return services;
    },

    statusOptions() {
      return [
        { text: "Todos", value: null },
        ...ServiceStatusConstants.completedOptions,
        ...ExtraFilters.options
      ];
    },

    selectedServiceId() {
      const isSelectedServiceAssigned =
        this.selectedService && this.selectedService.id;
      return isSelectedServiceAssigned
        ? this.selectedService.id.substr(this.selectedService.id.length - 6)
        : "";
    },

    showDatePicker() {
      return this.selectedFilter.date === DateFilters.NUM_CUSTOM;
    },

    drivers() {
      return this.$store.getters["drivers/activeDrivers"];
    },

    filterServiceType: {
      get() {
        return this.$store.state.services.filter.serviceType;
      },
      set(val) {
        this.$store.commit(
          `services/${mutation.UPDATE_FILTER_SERVICE_TYPE}`,
          val
        );
      }
    },

    serviceTypeOptions() {
      return UserServiceTypeOptions();
    },

    filterStatus: {
      get() {
        return this.$store.state.services.filter.status;
      },
      set(val) {
        this.$store.commit(`services/${mutation.UPDATE_FILTER_STATUS}`, val);
      }
    },

    filterVehicleType: {
      get() {
        return this.$store.state.services.filter.vehicleType;
      },
      set(val) {
        this.$store.commit(
          `services/${mutation.UPDATE_FILTER_VEHICLE_TYPE}`,
          val
        );
      }
    },

    filterCity() {
      return this.$store.getters["cities/getCity"];
    },

    stateFilters() {
      return this.$store.state.services.filter;
    },

    searchText: {
      get() {
        return this.$store.state.services.searchText;
      },
      set(val) {
        this.$store.commit(`services/${mutation.UPDATE_SEARCH_TEXT}`, val);
      }
    },

    disableDatesFrom() {
      return { from: new Date(this.date.until) };
    },

    disableDatesUntil() {
      return { to: new Date(this.date.from) };
    },

    paymentMethodFilterValue: {
      get() {
        return this.$store.getters["services/paymentMehodFilter"];
      },
      set(value) {
        this.$store.commit(
          `services/${mutation.UPDATE_FILTER_PAYMENT_METHOD}`,
          value
        );
      }
    },

    showCleanfilter() {
      return (
        this.selectedFilter.date != DateFilters.NUM_THIS_WEEK ||
        this.filterServiceType != null ||
        this.filterStatus != null ||
        this.searchText != ""
      );
    }
  },

  watch: {
    date: {
      deep: true,
      handler() {
        if (this.selectedFilter.date === DateFilters.NUM_CUSTOM) {
          this.getServicesAndResetPagination();
        }
      }
    },
    filterCity() {
      this.getServicesAndResetPagination();
    },
    paymentMethodFilterValue() {
      this.getServicesAndResetPagination();
    },

    searchText() {
      this.search();
    },

    stateFilters: {
      deep: true,
      handler() {
        this.getServicesAndResetPagination();
      }
    }
  },

  created() {
    this.search = _debounce(() => {
      this.getServicesAndResetPagination();
    }, 600);
  },

  beforeMount() {
    this.registerListeners(this.fetchServices);
    this.fetchServices();
  },

  mounted() {
    this.$store.subscribe(mutation => {
      if (mutation.type === "services/removeService") {
        this.fetchServices();
      }
    });
  },

  beforeDestroy() {
    if (this.unsuscribe) this.unsuscribe();
  },

  beforeRouteLeave(to, from, next) {
    if (to.name !== "services" && this.hasStatisticsFilter) {
      this.$store.commit(`services/${mutation.UPDATE_FILTER_STATUS}`, null);
      this.$store.commit(
        `services/${mutation.UPDATE_FILTER_DATE}`,
        DateFilters.NUM_THIS_WEEK
      );
      this.$store.commit(`services/${mutation.UPDATE_FILTER_CITY}`, null);
      this.$store.commit(
        `services/${mutation.UPDATE_FILTER_HAS_STATISTICS_FILTERS}`,
        false
      );
    }
    next();
  },

  methods: {
    chageSort() {
      this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
      this.fetchServices();
    },

    fetchServices() {
      this.isLoading = true;
      const where = this.buildWhereFilter();
      this.$store
        .dispatch("services/servicesHistory", {
          where,
          filter: {
            ...this.pagination,
            order: `${this.sortBy} ${this.sortOrder}`
          }
        })
        .catch(err => {
          const message =
            err?.message || "Error al intentar obtener los servicios";
          this.notify({
            type: "error",
            text: message
          });
          this.$captureError(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    buildWhereFilter() {
      const where = { like: this.searchText };

      /* Set service type filter */
      if (this.filterServiceType !== null) {
        if (ServiceType.isMultiPoint(this.filterServiceType)) {
          const { NUM_SIMPLE, NUM_MULTI_POINT, NUM_DYNAMIC } = ServiceType;
          where.type = [NUM_SIMPLE, NUM_MULTI_POINT, NUM_DYNAMIC];
        } else if (ServiceType.isParcel(this.filterServiceType)) {
          where.type = ServiceType.parcelTypes;
        } else {
          where.type = [this.filterServiceType];
        }
      }

      /* Set vehicle type filter */
      if (this.filterVehicleType !== null) {
        where.vehicleType = this.filterVehicleType;
      }

      if (
        this.filterStatus !== null &&
        this.filterStatus >= 0 &&
        this.filterStatus < 11
      ) {
        where.status = this.filterStatus;
      } else if (this.filterStatus === 13) {
        // Service with incidences
        where.hasIncidence = true;
        // Recalculated at this point is null in database. Never exist in false
        where.recalculated = { eq: null };
      } else if (this.filterStatus === 14) {
        // Recalculated service
        where.recalculated = true;
      }

      // Archived service
      if (this.filterStatus === 12) {
        where.archived = true;
      } else {
        where.archived = false;
      }

      if (this.selectedFilter.date !== DateFilters.NUM_CUSTOM) {
        const newDate = dateRange(this.selectedFilter.date);
        if (newDate.from != null && newDate.until != null) {
          newDate.from = formatToISO(newDate.from);
          newDate.until = formatToISO(newDate.until);
        }
        this.date = newDate;
      }

      if (this.date.from && this.date.until) {
        const from =
          this.date.from instanceof Date
            ? formatToISO(this.date.from)
            : this.date.from;
        const until =
          this.date.until instanceof Date
            ? this.date.until
            : parseISO(this.date.until);
        where.range = {
          from,
          until:
            this.selectedFilter.date === DateFilters.NUM_CUSTOM
              ? formatToISO(endOfDay(until))
              : formatToISO(until)
        };
      }

      if (this.paymentMethodFilterValue != null) {
        if (this.paymentMethodFilterValue === PaymentType.NUM_WALLET) {
          where.walletId = { neq: null };
        }
        if (this.paymentMethodFilterValue === PaymentType.NUM_CARD) {
          where.cardId = { neq: null };
        }
      }

      if (this.filterCity) {
        where.cityId = this.filterCity;
      }

      return where;
    },

    filterByDate() {
      if (this.selectedFilter.date !== DateFilters.NUM_CUSTOM)
        this.fetchServices();
    },

    ViewDriverDetail(driverId) {
      if (driverId) {
        this.$router.push({ name: "driverDetail", params: { id: driverId } });
      } else {
        this.notify({
          type: "warn",
          text: "No hay conductor asignado al servicio"
        });
      }
    },

    ViewUserDetail(clientId) {
      if (clientId) {
        this.$router.push({ name: "clientDetail", params: { id: clientId } });
      } else {
        this.notify({
          type: "warn",
          text: "No hay usuario asignado al servicio"
        });
      }
    },

    ViewServiceDetail(service) {
      if (
        this.$can(
          Access.services.READ_SERVICE_DETAIL,
          Access.services.moduleName
        )
      ) {
        this.$router.push({
          name: "serviceDetail",
          params: { id: service.id }
        });
      }
    },

    archive(serviceId) {
      this.$store.dispatch("services/archiveService", serviceId).catch(err => {
        const message =
          err?.message || "Error al intentar archivar el servicio";
        this.notify({
          type: "error",
          text: message
        });
      });
    },

    ViewServiceLogs(id) {
      this.$router.push({
        name: "logs",
        query: { service: id }
      });
    },

    vehicle(type) {
      switch (type) {
        case VehicleType.NUM_MOTO:
          return "zubut-motorcycle";
        case VehicleType.NUM_CAR:
          return "zubut-car";
        case VehicleType.NUM_ECO:
          return "zubut-bike";
        default:
          return "zubut-motorcycle";
      }
    },

    paginationReset() {
      this.currentPage = 1;
    },

    getServicesAndResetPagination() {
      this.paginationReset();
      this.fetchServices();
    },

    handleDownloadError(message) {
      this.hasError = true;
      this.message = message;
    },

    paymentType(service) {
      if (service.walletId) return "Wallet";
      if (service.cardId) return "Tarjeta";
      return "N/D";
    },

    resetFilter() {
      this.$store.commit(`services/${mutation.RESET_FILTERS}`);
    },

    parseRegistry(service) {
      const deliveries = service?.registry?.deliveriesNo || 0;
      const returnedNo = service?.registry?.returnedNo || 0;
      return `${deliveries} / ${returnedNo}`;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .date-picker-input {
  width: 120px;
}

.action {
  font-size: 12px;
  line-height: 30.5px;
  color: $zubut-blue;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.additional-hours-tooltip {
  transform: translateX(35px);
  .additional-hours-avatar {
    transform: translateX(-35px);
  }
}
</style>
