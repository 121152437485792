<template>
  <div>
    <transition name="slide-fade">
      <div v-show="showFilters" class="filters">
        <div class="d-flex justify-content-between px-4 pt-4 pb-1">
          <z-button class="close-button" variant="secondary" @click="cancel">
            <font-awesome-icon icon="times" />
          </z-button>
          <z-button @click="resetFilters">
            Borrar filtros
          </z-button>
        </div>
        <hr />
        <div class="px-4">
          <div class="mt-3">
            <label for="service-date">Estado</label>
            <z-dropdown
              id="dropdown-service-status"
              v-model="selectedServiceStatus"
              :options="statusOptions"
            />
          </div>
          <div class="mt-4">
            <label for="service-date">Tipo de servicio</label>
            <z-dropdown
              id="dropdown-service-type"
              v-model="selectedServiceType"
              :options="serviceTypeOptions"
            />
          </div>
        </div>
      </div>
    </transition>
    <z-button class="floating-button" @click="toggleMenu">
      <font-awesome-icon
        v-show="!showFilters"
        class="icon"
        icon="sliders-h"
        size="lg"
      />
      <font-awesome-icon
        v-show="showFilters"
        class="icon"
        icon="check"
        size="lg"
      />
    </z-button>
  </div>
</template>

<script>
import ZDropdown from "@zubut/common/src/components/ZDropdown";
import ServiceStatusConstants from "@/constants/services/status";
import ServiceType from "@zubut/common/src/constants/services/type";
import * as mutation from "@/store/modules/services/mutations-types";

const extraFilters = [
  { text: "Archivado", value: 12 },
  { text: "Con Incidencias", value: 13 },
  { text: "Recalculado", value: 14 }
];

export default {
  name: "ServicesFloatingButtonFilterVue",

  components: {
    ZDropdown
  },

  data() {
    return {
      showFilters: false,
      statusOptions: [{ text: "Todos", value: null }]
        .concat(ServiceStatusConstants.completedOptions)
        .concat(extraFilters),
      serviceTypeOptions: [{ text: "Todos", value: null }].concat(
        ServiceType.options
      ),
      selectedServiceStatus: null,
      selectedServiceType: null
    };
  },

  computed: {
    filterServiceType: {
      get() {
        return this.$store.state.services.filter.serviceType;
      },
      set(val) {
        this.$store.commit(
          `services/${mutation.UPDATE_FILTER_SERVICE_TYPE}`,
          val
        );
      }
    },

    filterStatus: {
      get() {
        return this.$store.state.services.filter.status;
      },
      set(val) {
        this.$store.commit(`services/${mutation.UPDATE_FILTER_STATUS}`, val);
      }
    }
  },

  watch: {
    filterServiceType: {
      immediate: true,
      handler(newVal) {
        this.selectedServiceType = newVal;
      }
    },
    filterStatus: {
      immediate: true,
      handler(newVal) {
        this.selectedServiceStatus = newVal;
      }
    }
  },

  methods: {
    toggleMenu() {
      this.showFilters = !this.showFilters;

      if (this.showFilters === false) {
        this.filterServiceType = this.selectedServiceType;
        this.filterStatus = this.selectedServiceStatus;
      } else {
        this.setFiltersToState();
      }
    },
    cancel() {
      this.showFilters = false;
      this.setFiltersToState();
    },
    resetFilters() {
      this.selectedServiceStatus = null;
      this.selectedServiceType = null;
    },
    setFiltersToState() {
      this.selectedServiceType = this.filterServiceType;
      this.selectedServiceStatus = this.filterStatus;
    }
  }
};
</script>

<style lang="scss" scoped>
.floating-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 2em;
  bottom: 2em;
  border-radius: 50%;
  border: none;
  min-width: 50px;
  height: 50px;
  padding: 0;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.6);
  z-index: 1003;

  &:focus {
    outline: none;
  }
}

.filters {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: $white;
  z-index: 1002;
}

.close-button {
  min-width: auto;
  background-color: transparent;
  font-size: 1.5em;
  padding: 0.2em;
}

label {
  font-weight: 600;
  color: $charcoal;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(300px);
  opacity: 0;
}
</style>
