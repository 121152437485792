const NUM_ARCHIVED = 12;
const NUM_INCIDENCES = 13;
const NUM_RECALCULATED = 14;

const ARCHIVED = "Archivado";
const INCIDENCES = "Con Incidencias";
const RECALCULATED = "Recalculado";

const options = [
  { text: ARCHIVED, value: NUM_ARCHIVED },
  { text: INCIDENCES, value: NUM_INCIDENCES },
  { text: RECALCULATED, value: NUM_RECALCULATED }
];

export default {
  options,
  NUM_ARCHIVED,
  NUM_INCIDENCES,
  NUM_RECALCULATED
};
