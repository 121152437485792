<template>
  <div>
    <b-dropdown
      menu-class="service-action-dropdown-menu"
      variant="link"
      size="lg"
      no-caret
      dropleft
      lazy
    >
      <template v-slot:button-content>
        <div>
          <font-awesome-icon icon="ellipsis-h" />
        </div>
      </template>
      <b-dropdown-item
        v-if="
          $can(access.services.READ_SERVICE_DETAIL, access.services.moduleName)
        "
        @click.stop="$emit('service-detail')"
      >
        Ver Servicio
      </b-dropdown-item>
      <b-dropdown-item @click.stop="$emit('client-detail')">
        Ver Cliente
      </b-dropdown-item>
      <b-dropdown-item
        v-if="$can(access.services.ARCHIVE_SERVICE, access.services.moduleName)"
        @click.stop="$emit('driver-detail')"
      >
        Ver Mensajero
      </b-dropdown-item>
      <b-dropdown-item
        v-if="archiveService"
        @click.stop="showArchiveConfirmation = true"
      >
        Archivar Servicio
      </b-dropdown-item>
      <b-dropdown-item v-if="restoreService" @click.stop="$emit('archive')">
        Restaurar Servicio
      </b-dropdown-item>
      <b-dropdown-item @click.stop="$emit('services-log')">
        Ver Actividad
      </b-dropdown-item>
    </b-dropdown>
    <confirmation-dialog
      close-on-cancel
      :show.sync="showArchiveConfirmation"
      @accept="$emit('archive')"
    >
      ¿Deseas archivar el servicio
      <b>{{ shortServiceId }}</b
      >?
    </confirmation-dialog>
  </div>
</template>

<script>
import ConfirmationDialog from "@/app/components/ConfirmationDialog";
import RoleType from "@/constants/roles/type.js";
import ServiceStatus from "@/constants/services/status";
import Access from "@/constants/access";
import { makeShortUUID } from "@/utils/strings";

export default {
  name: "ServiceActionsMenu",

  components: {
    ConfirmationDialog
  },

  props: {
    serviceId: {
      type: String,
      default: ""
    },
    status: {
      type: Number,
      required: true
    },
    archived: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      statusNo: ServiceStatus,
      access: Access,
      showArchiveConfirmation: false
    };
  },

  computed: {
    shortServiceId() {
      return this.serviceId ? makeShortUUID(this.serviceId) : "";
    },
    useRoleType() {
      return this.$store.state.auth.user.role.type;
    },
    archiveService() {
      return (
        this.$can(
          this.access.services.ARCHIVE_SERVICE,
          this.access.services.moduleName
        ) &&
        !this.archived &&
        this.status !== this.statusNo.NUM_CREATED &&
        this.status !== this.statusNo.NUM_REQUESTING &&
        this.status !== this.statusNo.NUM_ACTIVE
      );
    },
    restoreService() {
      return this.archived;
    },
    canRestoreService() {
      return (
        this.useRoleType === RoleType.NUM_SUPER_ADMIN ||
        this.useRoleType === RoleType.NUM_OPERATIONS
      );
    }
  }
};
</script>

<style lang="scss">
.service-action-dropdown-menu {
  .dropdown-item {
    font-size: 12px;
    background-color: $white;
    color: $charcoal;

    &:hover {
      background-color: rgba($royal-blue, 0.06);
    }
  }

  .dropdown-activity {
    color: $charcoal;
  }
}
</style>
